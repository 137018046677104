
<template>
    <div>
      
       
  
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">
            <h4 class="card-title">
              <span>{{RelName}}Danfe Simplificada</span>
            </h4>              
          </div>
  
          <b-collapse visible  id="collapse-filtro">
          <div class="card-body">
  
            <b-row>
              
               <b-col md="6" xl="4" class="mb-1">
                    <b-form-group
                     label="Ordem de Carga"
                    >
                        <b-form-input
                        id="ORDEMCARGA"
                        placeholder="Número da Ordem"
                        v-model="ORDEMCARGA"
                        type="number"
                        />
                    </b-form-group>
                </b-col>
            </b-row>     
          <b-row>
              
              <b-col
                  md="6"
                  xl="12"
                  class="mb-1"
                >
                <div class="DivBotaoLadoLado" >
                 <b-button
                variant="danger"
                type="button"
                @click="$router.push('../Relatorios')"
              >
                <span>RETORNAR</span>
              </b-button>
                </div>
                <div class="DivBotaoLadoLado" >
              
              <b-button 
                variant="primary"
                type="submit"
                @click="ConsultarRelatorio"
              >
                <span>CONSULTAR</span>
              </b-button>
                </div>
            </b-col>
           </b-row>
           
          </div>
          </b-collapse>
          
        </div>
      </div>
  
  
    </div>
  </template>
  <script>
  
  import Multiselect from 'vue-multiselect'
  import VueApexCharts from 'vue-apexcharts'
  import 'bootstrap-icons/font/bootstrap-icons.css'
  import apiPortalSky from '@/services/apiPortalSky'
  import AlertaMsg from '@/customPortal/AlertaMsg.vue'
  import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
  import Spinner from '@/components/Spinner.vue'
  import UtilVUE from '@/customPortal/UtilVUE.vue'
  
  import {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    VBToggle,
    BFormDatepicker,
    BImg 
    
    
  } from 'bootstrap-vue'
  
  export default {
    components: {
      VueApexCharts,
      Multiselect,
      BFormGroup,
      BRow,
      BCol,
      BButton,
      BTable,
      BFormSelect,
      BFormInput,
      BCollapse,
      BCard,
      BFormDatepicker,
      BImg,
    },
    directives: { 'b-toggle': VBToggle },
    data() {
      return {
        showFiltro: true,
        FiltroRelatorio: [],
        RelName: '',
        RelRota: '',
        ORDEMCARGA: '',
      }
    },
    methods: {
      async MostraReport() {
        let notification = ""
        Spinner.StarLoad()
        const usuario = this.$session.get('usuario')
        
        let ParametroJson = ""
        let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data != null) {
              this.RelName = response.data.descricaoRelatorio
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
  
        if(notification === '') {
         Spinner.EndLoad()
        }
  
      },
      async ConsultarRelatorio() {
        Spinner.StarLoad()
  
        //Inicio Dados Base Relatorio
        this.showFiltro = false
        const usuario = this.$session.get('usuario')
        let notification = ""
        this.FiltroRelatorio = []
        //Fim Dados Base Relatorio
        
        //Inicio Limpa Dados Retorno
        // Fim Limpa Dados Retorno   
  
        let ParametroJson = ''
        let URLpost = ''
        let TabResponse = null
  
        if(notification === '') {
        ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
        URLpost = `/Report/ExecutaRelDanfeSimplificada?CODUSU=${usuario.codusu}&_ordemcarga=${this.ORDEMCARGA}&TypeRDLCexport=pdf`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data != null) {
               TabResponse = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
              
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
          
        })
  
        if(TabResponse != null)
        {
          await UtilVUE.DownloadFile(TabResponse.reportByte, TabResponse.descricaoRelatorio + TabResponse.extensao)            
        }
  
      }
       if(notification === '') {
         Spinner.EndLoad()
       }
  
      },
    },
    computed: {
    },
    mounted() {
      if (this.$route.name != null) {
        this.RelRota = this.$route.name
        this.PeridoIni = new Date()
        this.PeridoFin = new Date()
        this.MostraReport()
        this.ConsultarOpcoes()
      }
    },
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  
  
  